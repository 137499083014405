<template>
  <th class="dce-choice-headers">
    <div class="cell-content">
      <div
        class="d-flex justify-content-center dce-project-title-wrapper"
        @click="mobileScrollToFunction"
      >
        <div class="dce-project-title-value">
          <LanguageSelector
            v-if="isLabeled"
            :to-be-selected="option.title"
            class="dce-project-title color-text-blue"
          />
          <span
            v-else
            class="dce-project-title color-text-blue"
          >
            {{ getUnlabeledTitle }}
          </span>
        </div>
      </div>
    </div>
  </th>
</template>

<script lang="ts">
import { mapActions } from 'pinia';
import { defineComponent } from 'vue';
import { useConsultationStore } from '../stores/useConsultationStore';
export default defineComponent({
  props: {
    option: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    mobileScrollToFunction: {
      type: Function,
      required: true,
    },
  },
  computed: {
    isLabeled() {
      return Object.keys(this.option.title).length > 0;
    },
    getUnlabeledTitle() {
      const prefix = this.resolveLabel('pagination.dce_unlabeled_option_header');
      return `${prefix} ${this.index + 1}`;
    },
  },

  methods: {
    ...mapActions(useConsultationStore, [
      'resolveLabel',
    ])
  }
});
</script>
