// DEPRECATED: Respondenten was bought by Norstat.

import qs from 'query-string';

export const Respondenten = {
  QUERY_STRING_KEY: 'projectusesrespondentguid',

  NAME: 'Respondenten',

  isPanelUser(queryString: string) {
    return queryString.toLowerCase().includes(this.QUERY_STRING_KEY);
  },

  getPanelParams(queryString: string) {
    const parsed = qs.parse(queryString.toLowerCase());
    return { projectusesrespondentguid: parsed.projectusesrespondentguid };
  },
};
