import { isLocal } from '../..//utils/isLocal';
import { LOCAL_SLUG } from '../../settings';

const SLUG_MAPPING: Record<string, string[]> = {
  onderzoeksfinanciering: [
    'onderzoeksfinanciering1',
    'onderzoeksfinanciering2',
    'onderzoeksfinanciering3',
  ],
  fwg2s: [
    'fwg2s1',
    'fwg2s2',
    'fwg2s3',
    'fwg2s4',
    'fwg2s5',
    'fwg2s6',
    'fwg2s7',
    'fwg2s8',
    'fwg2s9',
    'fwg2s10',
  ],
  fwg2p: ['fwg2p1', 'fwg2p2'],
  energieschaarste: ['energieschaarste1', 'energieschaarste2'],
};

function parseSubdomain() {
  const host = window.location.host;
  const parts = host.split('.');
  const domainLength = parts.length;

  if (domainLength === 3) {
    // To handle '{slug}.domain.com'
    return parts[0];
  } else if (domainLength > 3) {
    // To handle 'www.{slug}.domain.com'
    return parts.reverse()[2];
  }

  return ''
}

function getRandomSlug(slugs: string[]): string {
  return slugs[Math.floor(Math.random() * slugs.length)];
}

function processSlugMapping(slug: string) {
  if (SLUG_MAPPING[slug]) {
    return getRandomSlug(SLUG_MAPPING[slug]);
  }

  return slug;
}

export function getConsultationSlugFromRoute(): string {
  let slug;

  if (isLocal()) {
    slug = LOCAL_SLUG()
  } else {
    slug = parseSubdomain();
  }

  return processSlugMapping(slug);
}
