import { ConsultationData } from "../../../types/ConsultationData";
import { InstanceData } from "../../../types/InstanceData";
import { PrimaryBlockType } from "../../../types/PrimaryBlockType";
import { StageLanguageSelection } from "../../../types/StageLanguageSelection";
import { BlockType } from "../../Segments";
import { ChoiceTaskAdapter } from "./ChoiceTaskAdapter";
import { ConsentAdapter } from "./ConsentAdapter";
import { DiscreteChoiceAdapter } from "./DiscreteChoiceAdapter";
import { IntroAdapter } from "./IntroAdapter";
import { OutroAdapter } from "./OutroAdapter";
import { SurveyAdapter } from "./SurveyAdapter";

export function InstanceAdapter(data: InstanceData): ConsultationData {
  const segments = data.segments || [];
  delete data.segments;
  const result: ConsultationData = { ...data, stages: [] };

  if (hasMultipleLocales(data)) {
    result.stages.push(createLanguageSelectionStage(data));
  }

  segments.forEach(segment => {
    const Adapter = resolveAdapter(segment.block_type);
    if (Adapter) {
      const stages = Adapter(segment as any);
      result.stages.push(...stages);
    }
  });

  return result;
}

function hasMultipleLocales(data: InstanceData): boolean {
  return Object.keys(data.settings.locales).length > 1;
}

function createLanguageSelectionStage(data: InstanceData): StageLanguageSelection {
  return {
    id: 'language-selection',
    stageType: 'language-selection',
    blockId: 0,
    blockType: 'language_selection',
    component: 'SegmentLanguageSelection',
    hidden: false,
    fields: {
      default: data.settings.default_locale,
      options: data.settings.locales,
    },
  };
}

function resolveAdapter(type: PrimaryBlockType): Function | null {
  const adapterMap: { [key in PrimaryBlockType]?: Function } = {
    [BlockType.Consent]: ConsentAdapter,
    [BlockType.Intro]: IntroAdapter,
    [BlockType.Outro]: OutroAdapter,
    [BlockType.Questions]: SurveyAdapter,
    [BlockType.Research]: ChoiceTaskAdapter,
    [BlockType.Dce]: DiscreteChoiceAdapter,
  };

  return adapterMap[type] || null;
}
