import { BlockIntro } from "../../../types/BlockIntro";
import { StageIntro } from "../../../types/StageIntro";

export function IntroAdapter(data: BlockIntro): StageIntro[] {
  if (data.stages.length === 0) {
    return [];
  }

  const segments: StageIntro[] = [];

  for (const stage of data.stages) {
    const segment: StageIntro = {
      id: `intro-${stage.id}`,
      blockId: data.id,
      stageId: stage.id,
      stageType: 'intro',
      segmentType: 'Page',
      blockType: data.block_type,
      component: 'PageSegment',
      conditions: data.conditions,
      hidden: false,
      fields: {
        title: stage.title,
        content: stage.content,
        video: stage.video,
        videoMobile: stage.video_mobile,
        audioDescription: stage.audio_description_video,
        audioDescriptionMobile: stage.audio_description_video_mobile
      }
    }

    segments.push(segment);
  }

  return segments;
}
