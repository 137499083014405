import { HttpClient } from '../Http/HttpClient';
import { AxiosRequestConfig, Method } from 'axios';
import { API_BASE_URL } from '../../settings';

export interface Answers {
  [key: string]: {
    blockId: number;
    uuid: string | null;
    answers: any;
  };
}

export abstract class AnswerData {
  /**
   * The slug for the answer data type.
   */
  protected endpoint: string;

  protected blockId: number;

  protected answerData: Answers;

  protected instanceData: any;

  public abstract formatData(answer: any): any;

  public constructor(blockId: number, instanceData, answerData: Answers) {
    this.blockId = blockId;
    this.answerData = answerData;
    this.instanceData = instanceData;
  }

  public async submit() {
    const filteredAnswerData = this.getFilteredAnswerData();

    const uuid = this.findUuid(filteredAnswerData);

    const formatted = this.formatData(filteredAnswerData);

    const data = { ...this.formatInstanceData(), ...formatted };

    let method: Method = 'post';

    if (uuid) {
      data.uuid = uuid;
      method = 'put';
    }

    const http = new HttpClient(this.settings());

    try {
      const response = await http.request(method, this.endpoint, data);
      this.handleResponse(response);
    } catch (error) {
      console.error('Error submitting answer data', error);
    }
  }

  protected formatInstanceData() {
    return {
      instanceId: this.instanceData.instanceId,
      instanceStatus: this.instanceData.instanceStatus,
      language: this.instanceData.language,
      participantData: {
        isPanelUser: this.instanceData.isPanelUser,
        panelId: this.instanceData.panelId,
        panelSource: this.instanceData.panelSource,
        userSessionId: this.instanceData.userSessionId,
        userClientToken: this.instanceData.userClientToken,
      }
    }
  }

  protected handleResponse(response) {
    if (response.status === 200) {
      for (const [key, answerData] of Object.entries(this.answerData)) {
        if (answerData.blockId !== this.blockId) {
          continue;
        }

        this.answerData[key].uuid = response.data.uuid;
      }
    } else {
      console.error('Error submitting answer data', response);
    }
  }

  protected settings(): AxiosRequestConfig {
    return {
      baseURL: `${API_BASE_URL()}/pve/participants/`,
    };
  }

  protected getFilteredAnswerData() {
    const answers = {};

    for (const [key, answerData] of Object.entries(this.answerData)) {
      if (answerData.blockId !== this.blockId) {
        continue;
      }

      answers[key] = answerData;
    }

    return answers;
  }

  protected findUuid(answers: Answers) {
    for (const [key, data] of Object.entries(answers)) {
      if (data.uuid !== null) {
        return data.uuid;
      }
    }

    return null;
  }
}
