import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { API_BASE_URL } from '../../settings';

const IdentificationService = {
  async getClientToken() {
    const clientId = this._getClientId();

    let token;
    let success;

    try {
      token = await this._fetchClientToken(clientId);
      success = true;
    } catch (e) {
      success = this.retryClientToken();
    }

    if (!success) {
      throw new Error('Could not get client token');
    }

    return token;
  },
  retryClientToken() {
    let amountOfReloads = this._getAmountOfReloads();

    if (typeof amountOfReloads === 'number' && amountOfReloads < 3) {
      amountOfReloads++;
      this._setAmountOfReloads(amountOfReloads);
      return window.location.reload();
    } else {
      this._resetAmountOfReloads();
      return false;
    }
  },
  async _fetchClientToken(clientId: string) {
    const result = await axios.post(`${API_BASE_URL()}/pve/identification/token`, { clientId });
    return result.data.token;
  },
  _getClientId() {
    let clientId = localStorage.getItem('wevaluate.client_id');

    if (!clientId) {
      clientId = uuidv4();
      localStorage.setItem('wevaluate.client_id', clientId);
    }

    return clientId;
  },

  _getAmountOfReloads() {
    let amountOfReloads = localStorage.getItem('wevaluate.amountOfReloads');

    if (!amountOfReloads) {
      amountOfReloads = 1;
      localStorage.setItem('wevaluate.amountOfReloads', amountOfReloads);
    }

    return amountOfReloads;
  },

  _setAmountOfReloads(x) {
    localStorage.setItem('wevaluate.amountOfReloads', x);
  },

  _resetAmountOfReloads() {
    localStorage.removeItem('wevaluate.amountOfReloads');
  },
};

export default IdentificationService;
