import { Property } from "../../../types/Property";
import { StageDiscreteChoice } from "../../../types/StageDiscreteChoice";

export function DiscreteChoiceAdapter(data): StageDiscreteChoice[] {
  if (data.stages.length === 0) {
    return [];
  }

  const segments = [];

  let versions = data.stages.map((stage) => stage.version);
  versions = [...new Set(versions)];

  let versionId = versions[(Math.random() * versions.length) | 0];

  const urlParams = new URLSearchParams(window.location.search);
  const deepLink = urlParams.get('__wvdeeplink');

  if (deepLink) {
    const payload = JSON.parse(window.atob(deepLink));

    if (payload.parent_segment_id === data.id && payload.version !== undefined) {
      versionId = payload.version;
    }
  }

  const stages = data.stages.filter((stage) => stage.version === versionId);

  data.properties.forEach((property) => {
    property.values = [];
  });

  for (const [index, stage] of stages.entries()) {
    const properties = data.properties || [];

    properties.forEach((property) => {
      const versionData = stage.versionData;

      for (const [optionId, propertyValues] of Object.entries(versionData)) {
        if (propertyValues[property.id] !== undefined) {
          const value = parseInt(propertyValues[property.id]);
          property.values.push({
            id: parseInt(optionId),
            value: value
          });
        }
      }
    });

    segments.push({
      id: `discrete-choice-${stage.id}`,
      blockId: data.id,
      blockType: data.block_type,
      stageId: stage.id,
      stageType: 'discrete-choice',
      component: 'SegmentDiscreteChoice',
      conditions: data.conditions,
      hidden: false,
      fields: {
        version: versionId,
        versionData: stage.versionData,
        situationNumber: index + 1,
        situationCount: stages.length,
        slug: stage.slug,
        options: stage.options,
        properties: properties,
        doubleResponse: data.settings.choice_situation_double_response,
        doubleResponseDefaultTrue: data.settings.choice_situation_double_response_default_true,
        labels: data.labels,
      }
    });
  }

  return segments;
}
